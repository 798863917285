import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardText,[_c('DateTimePicker',{attrs:{"lock-date-from":true,"tooltip-date-to":_vm.$t('RateReportElements.DateToToolTip')},on:{"getDateFrom":function($event){_vm.dateFrom = $event},"getDateTo":function($event){_vm.dateTo = $event}}}),_c('br'),(!_vm.$checkRole('ADVERTISERS') && !_vm.$checkRole('ADVERTISERS_USERS') && !_vm.$checkRole('PROVIDERS'))?_c(VRow,[_c(VCol,{attrs:{"cols":"6","xl":"6","md":"6","sm":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.advertisers,"item-text":"name","item-value":"id","label":_vm.$t('Advertiser'),"disabled":_vm.loadingFirst,"clearable":""},model:{value:(_vm.advertiserId),callback:function ($$v) {_vm.advertiserId=$$v},expression:"advertiserId"}})],1),_c(VCol,{attrs:{"cols":"1","xl":"1","md":"1","sm":"1"}},[(_vm.loadingFirst)?_c(VProgressCircular,{staticClass:"mt-5",attrs:{"indeterminate":"","color":"primary","width":2,"size":20}}):_vm._e()],1)],1):_vm._e(),_c('br'),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.getDataFromDB}},[_vm._v(" "+_vm._s(_vm.$t('BtnSearch'))+" ")])],1)],1),_c('br'),_c('div',[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataFromDb,"item-key":"name","sort-by":_vm.orderBy,"sort-desc":_vm.sortDesc,"search":_vm.search,"loading":_vm.loading,"locale":_vm.$i18n.locale},on:{"update:sortBy":function($event){_vm.orderBy=$event},"update:sort-by":function($event){_vm.orderBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"}),_c('div',{staticClass:"col-4"},[_c(VTextField,{staticClass:"mx-4",attrs:{"label":_vm.$t('LblSearch')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.unitType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.unitType))+" ")]}},{key:"item.campaignDateStop",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.campaignDateStop).format("YYYY-MM-DD")))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }